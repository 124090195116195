.error-boundary {
  margin-top: 4rem;
  max-width: 500px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid;
  padding: 1rem;
  max-height: initial;
}
