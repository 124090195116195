.resend .section__content {
  @include tablet {
    max-width: 40rem;
  }

  @include desktop {
    display: flex;
    max-width: 50rem;
    justify-content: center;
  }
}

.resend__illustration {
  flex-shrink: 0;
  margin-left: 3rem;
  margin-top: 8rem;
}
