.testimonials-headline {
  padding: 0 calc(3% + 6px) 1rem;
  overflow: visible;

  @include tablet-desktop {
    padding: 0 calc(3% + 6px) 1rem;
    justify-content: center;
  }

  @include desktop {
    padding: 0 36px 0;
  }
}

.testimonials {
  display: flex;
  width: 100%;
  padding: 0.5rem 1.5% 1rem;

  @include mobile-tablet {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  @include tablet {
    padding: 1rem 1.5% 1rem;
  }

  @include desktop {
    padding: 1.5rem 18px 1rem;
  }
}

.testimonials__testimonial {
  display: flex;
  padding: 1.5rem 1rem 2.5rem;
  position: relative;
  background-color: $color-font--white;
  border-radius: 30px;
  margin: 0 calc(1.5vw + 6px);

  @include mobile {
    min-width: 70%;
  }

  @include tablet {
    min-width: 38%;
  }

  @include desktop {
    padding: 2rem 1rem 2.5rem;
    width: 33%;
    margin: 0 18px;
  }
}

.testimonials__image {
  margin-right: 0.5rem;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.testimonials__header {
  font-size: $font-size--small;
  align-items: flex-start;

  @include mobile-tablet {
    flex-direction: column;
  }
}

.testimonials__link {
  font-size: $font-size--small;
  white-space: nowrap;

  @include desktop {
    margin-left: 0.5rem;
  }
}

.testimonials__text {
  width: 100%;
  padding-bottom: 0;
}

.testimonials__icon {
  position: absolute;
  opacity: 0.3;
  bottom: -12px;
  // To create a fake margin/padding because the margin and padding
  // of the testimonial for the right side of the last element get ignored in a scroll container
  right: calc(-1rem - 1.5vw);
  padding-right: calc(1rem + 1.5vw);
}
