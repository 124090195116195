.checkbox {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
}

.checkbox--first {
  margin-top: 0;
}

.checkbox__input {
  flex-shrink: 0;

  &:focus {
    outline: 2px dashed $color--primary;
  }
}

.checkbox__label {
  user-select: none;
  margin: 0;
  padding: 0 0 0 0.5rem;
}
