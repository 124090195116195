.color-picker {
  display: flex;
  flex-wrap: wrap;
}

.color-picker__color {
  display: inline-block;
  margin-right: 6px;
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding: 0;
  position: relative;

  &:last-child {
    margin-right: 0;
  }
}

.color-picker__label--0 {
  background-color: $color--0;
}

.color-picker__label--1 {
  background-color: $color--1;
}

.color-picker__label--2 {
  background-color: $color--2;
}

.color-picker__label--3 {
  background-color: $color--3;
}

.color-picker__label--4 {
  background-color: $color--4;
}

.color-picker__label--5 {
  background-color: $color--5;
}

.color-picker__label--6 {
  background-color: $color--6;
}

.color-picker__label--7 {
  background-color: $color--7;
}

.color-picker__label--8 {
  background-color: $color--8;
}

.color-picker__legacy {
  .color-picker__label--1 {
    background-color: $color--7;
  }

  .color-picker__label--2 {
    background-color: $color--8;
  }

  .color-picker__label--3 {
    background-color: $color--3;
  }

  .color-picker__label--4 {
    background-color: $color--1;
  }

  .color-picker__label--5 {
    background-color: $color--5;
  }

  .color-picker__label--6 {
    background-color: $color--4;
  }

  .color-picker__label--7 {
    background-color: $color--2;
  }

  .color-picker__label--8 {
    background-color: $color--6;
  }

  .color-picker__label--9 {
    background-color: $color--0;
  }
}

.color-picker__icon {
  opacity: 0;
}

.color-picker__icon--active {
  opacity: 1;
}

.color-picker__icon,
.color-picker__icon .icon__svg {
  padding: 0;
  height: 100%;
  width: 100%;
}

.color-picker__input {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:focus ~ .color-picker__label {
    outline: 2px dashed $color--primary;
  }

  /* stylelint-disable selector-max-compound-selectors */
  @include desktop {
    &:hover ~ .color-picker__label > .color-picker__icon {
      opacity: 1;
    }
  }
  /* stylelint-enable selector-max-compound-selectors */
}

.color-picker__label {
  height: 2rem;
  width: 2rem;
  padding: 0;
  border: 2px solid rgba(0, 0, 0, 0.15);
  transition: border-color 0.2s linear;
}

.color-picker__name {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
