.bookmark {
  padding: 0 6px;
  position: relative;
}

.bookmark--edit-mode {
  padding-right: 0;
}

.bookmark__wrapper {
  display: flex;
  align-items: center;
  height: 36px;
  line-height: 36px;
}

.bookmark__link {
  color: $color-font--dark;
  flex-grow: 1;
  font-size: 1rem;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: color 0.2s linear;
  white-space: nowrap;
  line-height: 2;
  margin-left: 0.5rem;

  &:focus,
  &:active {
    color: $color-font--primary;
    text-decoration: underline;
  }

  @include desktop {
    &:hover {
      color: $color-font--primary;
      text-decoration: underline;
    }
  }
}

.bookmark__link--dark {
  color: $color-font--white;
}

.bookmark__favicon {
  flex-shrink: 0;
  pointer-events: initial;
  margin: 4px;
}

.bookmark__favicon--viewOnly {
  pointer-events: none;
}

.bookmark__fallback-icon {
  flex-shrink: 0;
  width: 24px;
  text-align: center;
  font-weight: bold;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-font--white;
  cursor: default;

  &.bookmark__fallback-icon--color1 {
    background-color: $color--7;
  }

  &.bookmark__fallback-icon--color2 {
    background-color: $color--8;
  }

  &.bookmark__fallback-icon--color3 {
    background-color: $color--3;
  }

  &.bookmark__fallback-icon--color4 {
    background-color: $color--1;
  }

  &.bookmark__fallback-icon--color5 {
    background-color: $color--5;
  }

  &.bookmark__fallback-icon--color6 {
    background-color: $color--4;
  }

  &.bookmark__fallback-icon--color7 {
    background-color: $color--2;
  }

  &.bookmark__fallback-icon--color8 {
    background-color: $color--6;
  }

  &.bookmark__fallback-icon--color9 {
    background-color: $color--0;
  }
}

.bookmark__note {
  padding-left: 24px;
  white-space: pre-wrap;
}

.bookmark--edit-mode .bookmark__note {
  padding-right: 6px;
}

.bookmark__note-icon-wrapper {
  position: relative;
  height: 36px;
  width: 36px;
}

.bookmark__note-icon {
  position: absolute;
  top: 8px;
  left: 10px;
}

.bookmark__note-icon--dark-mode {
  color: $color-background--dark-mode;
}
