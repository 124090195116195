.section {
  position: relative;
  margin-bottom: 3rem;

  @include tablet {
    margin-bottom: 4rem;
  }

  @include desktop {
    margin-bottom: 6rem;
  }
}

.section--wave {
  margin-bottom: calc(3rem + 40px);

  @include tablet {
    margin-bottom: calc(4rem + 40px);
  }

  @include desktop {
    margin-bottom: calc(6rem + 40px);
  }
}

.section--noMargin {
  margin-bottom: 0;
}

.section--compact .section__content {
  width: 500px;
  max-width: 100%;

  @include tablet-desktop {
    padding: 0 22px;
  }
}

.section--primary,
// .section--blue,
.section--green {
  .headline,
  .paragraph {
    color: $color-font--white;
  }
}

.section--primary {
  background-color: $color--primary;
}

.section--light {
  background-color: $color-background--light;

  &.section--darkMode {
    background-color: $color--dark;
  }

  .section__wave,
  .section__wave::after {
    background-color: $color-background--light;
  }
}

.section--blue,
.section--blue .section__wave,
.section--blue .section__wave::after {
  background-color: $color--blue;
}

.section--green,
.section--green .section__wave,
.section--green .section__wave::after {
  background-color: $color--green;
}

.section--dark,
.section--dark .section__wave,
.section--dark .section__wave::after {
  background-color: $color--dark;
}

.section--medium,
.section--medium .section__wave,
.section--medium .section__wave::after {
  background-color: $color-font--medium;
}

.section__content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 calc(3% + 6px);
  position: relative;

  @include desktop {
    padding: 0 36px;
  }
}

.section__content--fullWidth {
  padding: 0;
}

.section__content--more-space {
  @include mobile-tablet {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @include desktop {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
}

.section__wave {
  display: block;
  position: absolute;
  height: 40px;
  width: 100%;
  bottom: -40px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    background-color: $color-background--white;
    right: -25%;
    top: 20px;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    left: -25%;
    top: -240px;
  }
}

.section--wave-dark {
  &::before {
    background-color: $color-background--dark-mode;
  }
}
