.modal {
  bottom: 0;
  left: 0;
  position: fixed;
  width: 0;
  right: 0;
  top: 0;
  z-index: 6;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0s 0.2s linear;

  &::before {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: fixed;
    width: 0;
    top: 0;
    transition: opacity 0.2s linear, width 0s 0.2s linear;
  }

  .tooltip-wrapper {
    display: none;
  }
}

.modal--open {
  width: 100%;
  transition: none;

  &::before {
    opacity: 1;
    transition: opacity 0.2s linear;
    width: 100%;
    z-index: 6;
  }

  .modal__inner {
    transition: opacity 0.2s linear, transform 0.2s;
    opacity: 1;
    transform: none;
  }

  .tooltip-wrapper {
    display: block;
  }
}

.modal__inner {
  transition: opacity 0.2s linear, transform 0.2s;
  transform: scale(0.95);
  opacity: 0;
  z-index: 6;
  width: 94vw;
  max-height: 94%;
  background-color: $color-background--white;
  border: 2px solid rgba(0, 0, 0, 0.15);
  max-width: $width--mobile;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.modal__inner--dark {
  background-color: $color-background--dark-mode;
}

.modal__header {
  display: flex;
  align-items: center;
  padding: calc(1rem - 6px) calc(1rem - 6px) calc(1rem - 6px) 1rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}

.modal__headline {
  text-align: left;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

.modal__content {
  position: relative;
  width: 100%;
  padding: 1rem 1rem 0.5rem 1rem;
}

.modal__error {
  padding: 0.5rem 1rem 0 1rem;
}

.modal__footer {
  margin-top: 1rem;
  width: 100%;
  padding: 1rem;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}

.modal__footer--one-button {
  justify-content: flex-end;
}

.modal__button--cancel {
  margin-right: 0.5rem;
}

.modal__tab-index-link {
  opacity: 0;
  position: absolute;
  overflow: hidden;
}

.modal__note {
  margin-top: 1rem;
  margin-bottom: 0;
}

.modal__share-input-wrapper {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.modal__share-input.input__field {
  padding-right: 10px;
  margin-bottom: 0;
}

.modal__share-button {
  height: 34px;
  margin-left: 1rem;
  flex-shrink: 0;
}
