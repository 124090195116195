.page {
  background-color: $color-background--white;
  position: relative;
  min-height: 100vh;
  padding: calc(4rem - 47px) 0 6rem 0;

  @include tablet {
    padding: calc(6rem - 70px) 0 10rem 0;
  }

  @include desktop {
    padding: calc(10rem - 101px) 0 14rem 0;
  }
}

.page--sticky-header {
  padding: 4rem 0 6rem 0;

  @include tablet {
    padding: 6rem 0 10rem 0;
  }

  @include desktop {
    padding: 9.3rem 0 14rem 0;
  }
}

.page--blur {
  filter: blur(3px) grayscale(100%);
}

.page--dark {
  background-color: $color-background--dark-mode;
}

.page--update {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
}
