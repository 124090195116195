.sidebar {
  top: 0;
  background-color: $color-background--white;
  bottom: 0;
  max-width: 300px;
  min-width: 200px;
  padding-bottom: 1rem;
  position: fixed;
  transition: transform 0.3s ease;
  width: 80%;
  overflow: hidden;
  z-index: 5;
}

.sidebar--dark-mode {
  background-color: $color-background--medium;
}

.sidebar--left {
  left: 0;
  transform: translate3d(-100%, 0, 0);

  .sidebar__logo {
    margin-left: 1rem;
  }

  .sidebar__header {
    border-right: 2px solid rgba(0, 0, 0, 0.1);
  }

  .sidebar__scroll-wrapper {
    border-right: 2px solid rgba(0, 0, 0, 0.15);
  }
}

.sidebar--right {
  right: 0;
  transform: translate3d(100%, 0, 0);

  .sidebar__header {
    border-left: 2px solid rgba(0, 0, 0, 0.1);
  }

  .sidebar__scroll-wrapper {
    border-left: 2px solid rgba(0, 0, 0, 0.15);
  }
}

.sidebar--open {
  transform: translate3d(0, 0, 0);
}

.sidebar__header {
  display: flex;
  height: calc(3rem + 2px);
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  padding: 0 3vw;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);

  @include tablet {
    height: calc(4.4rem + 2px);
  }
}

.sidebar__scroll-wrapper {
  position: absolute;
  top: 3rem;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @include tablet {
    top: 4.4rem;
  }
}

.sidebar__headline {
  padding: 0 calc(3vw + 6px);
  margin: 0;
  line-height: 2;
  width: 100%;
}

.sidebar__logo {
  margin: 0 6px;

  &:focus {
    outline: 1px dashed $color-font--white;
  }
}

.sidebar__icon {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

.sidebar__edit-icon--hide {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

.sidebar__nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.sidebar__list {
  list-style: none;
  width: 100%;
}

.sidebar__item {
  color: $color-font--dark;
  background-color: $color-background--white;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 2.5rem;
  line-height: 2.5;
  width: 100%;
  list-style: none;
  padding: 0 calc(3vw + 6px) 0 3vw;
  user-select: none;
  transition: background-color 0.2s linear, color 0.2s linear;
  cursor: pointer;

  .icon {
    transition: none;
  }

  &.sidebar__item--dark-mode {
    background-color: $color-background--medium;

    &::before {
      background-color: $color-font--white;
    }
  }

  &::before {
    background-color: $color-font--medium;
  }

  &:active,
  &:focus {
    background-color: $color-background--light;
    color: $color-font--primary;

    &::before {
      background-color: $color-font--primary;
    }

    &.sidebar__item--dark-mode {
      background-color: $color-background--dark;
    }

    .sidebar__label {
      color: $color-font--primary;
    }

    .icon {
      color: $color-font--primary;
    }
  }

  &.link::before {
    right: auto;
    left: 13px;
  }
}

.sidebar__item--highlighted {
  font-weight: bold;
}

.sidebar__label {
  color: $color-font--dark;
  font-family: $font-family--text;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    height: 2px;
    left: 0;
    right: 0;
    bottom: 4px;
    background-color: transparent;
  }
}

.sidebar__label--dark-mode {
  color: $color-font--white;
}

.sidebar__item--active {
  .sidebar__label {
    &::after {
      background-color: $color-font--dark;
    }
  }

  .sidebar__label--dark-mode {
    &::after {
      background-color: $color-font--white;
    }
  }

  &:active,
  &:focus {
    .sidebar__label {
      &::after {
        background-color: $color-font--primary;
      }
    }
  }
}

.sidebar__hr {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
}

.sidebar__button {
  margin: 1rem calc(3vw + 6px);
  width: calc(100% - 6vw - 12px);
}
