.home {
  padding-top: 0;
  overflow: hidden;
}

.home__header {
  position: relative;
  max-width: 1270px;
  margin: 0 auto;
  padding: 0 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include mobile {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

  @include tablet {
    padding-top: 4rem;
  }

  @include desktop {
    padding-top: 6rem;
  }
}

.home__header-wrapper {
  flex-shrink: 0;
}

.home__header-illustration {
  height: auto;
  margin-top: -3rem;
  flex-grow: 1;
  width: 80%;
  position: relative;
  bottom: -10vw;
  z-index: 1;

  @include mobile {
    margin-top: -15vw;
    bottom: -23vw;
    width: 100%;
  }

  @include tablet {
    margin-top: -2rem;
  }

  @include hd {
    bottom: -8rem;
  }
}

.home__join {
  @include mobile {
    margin-bottom: 1rem;
  }

  @include mobile-tablet {
    margin-top: 1rem;
  }

  @include tablet-desktop {
    margin-right: 1rem;
  }

  @include desktop {
    margin-top: 2rem;
  }
}

.home__navigation {
  display: flex;
  justify-content: center;

  @include desktop {
    padding: 0 36px;
  }
}

.home__nav-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 25%;
  transition: color 0.2s;
  color: $color-font--medium;
  text-decoration: none;
  font-weight: 700;

  &:active,
  &:focus {
    color: $color-font--dark;

    .icon__svg {
      color: $color--primary;
    }
  }

  @include desktop {
    &:hover {
      color: $color-font--dark;

      .icon__svg {
        color: $color--primary;
      }
    }
  }

  .icon,
  .icon__svg {
    transition: color 0.2s;
    margin-bottom: 0.3rem;
  }
}

.home__plant {
  position: absolute;
  bottom: 0;
  height: auto;
  max-width: 12rem;
  width: 12vw;
  left: 3%;

  @include desktop {
    width: 19vw;
    left: 36px;
  }

  @include hd {
    left: 0;
  }
}

.home__trees {
  position: absolute;
  bottom: 0;
  height: auto;
  max-width: 15rem;
  right: -7rem;
  width: 22vw;
}

.home__availability {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home__availability-title {
  @include desktop {
    max-width: 80%;
  }
}

.home__platforms {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include tablet {
    max-width: 80%;
  }

  @include desktop {
    max-width: 60%;
  }
}

.home__platforms-platform {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
}

.home__platforms-icon {
  margin: 0 0.5rem 0.5rem;
  max-width: 75px;
  max-height: 75px;

  @include mobile {
    width: 19vw;
    height: 19vw;
  }
}

.home__features {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    padding-top: 13vw;
  }

  @include tablet {
    padding-top: 6rem;
  }

  @include desktop {
    padding-top: 10rem;
  }
}

.home__features-headline {
  @include mobile {
    justify-content: flex-start;
    text-align: left;
    padding-bottom: 0;
  }
}

.home__not-a-member {
  text-align: center;
}

.home__login-button {
  margin-top: 2rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.home__heart {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.survey {
  margin: 3rem 3% 0 3%;
  padding: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  max-width: 47rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include tablet-desktop {
    flex-direction: row;
  }

  @include desktop {
    margin: 30px 36px 0 36px;
  }
}

.survey--dark-mode {
  background-color: $color--dark;
}

.survey--sidebar {
  @include desktop {
    width: calc(100% - 122px);
    margin-left: 86px;

    &.survey--shifted {
      width: calc(100% - 372px);
      margin-left: 336px;
    }
  }
}

.survey__text {
  flex-grow: 1;
}

.survey__buttons {
  white-space: nowrap;
  margin-top: 1rem;

  @include tablet-desktop {
    margin-top: 0;
  }
}

.survey__confirm {
  margin: 0 1rem;
}

.home__availability-wrapper {
  overflow: hidden;
}
