.open__search-field {
  margin-left: 0;
  margin-top: 1.5rem;
  width: 100%;
}

.open__search {
  padding: 0 6px;
  margin-top: 0.5rem;
}
