.file-upload {
  font-size: 1rem;
  width: 100%;
  color: $color-font--dark;
  margin-top: 0.5rem;
}

.file-upload--dark-mode {
  color: $color-font--white;
}
