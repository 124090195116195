.paragraph {
  font-family: $font-family--text;
  line-height: 1.5;
  padding-bottom: 1rem;
  word-wrap: break-word;
}

.paragraph--first {
  margin-top: 1rem;
}

.paragraph--no-padding {
  padding: 0;
}

.paragraph--small {
  font-size: $font-size--small;
}

.paragraph--normal {
  font-size: 1rem;
}

.paragraph--large {
  font-size: 1.3rem;
}

.paragraph--dark {
  color: $color-font--dark;
}

.paragraph--light {
  color: $color-font--white;
}

.paragraph--blue {
  color: $color--blue;
}

.paragraph--dark-mode {
  color: $color-font--white;
}
