.customize--extension {
  padding-bottom: 1rem;
}

.customize__error {
  padding: 1rem 0 0 0;
}

.customize__image--first {
  display: inline-block;
  margin-right: 0.5rem;
  max-width: calc(50% - 0.5rem);
}

.customize__image--second {
  display: inline-block;
  margin-left: 0.5rem;
  max-width: calc(50% - 0.5rem);
}

.customize__max-columns {
  width: 5rem;
}

.customize__logout {
  &:hover,
  &:focus {
    background-color: $color-font--primary;
    color: $color-font--white;
  }

  &:hover .icon,
  &:focus .icon {
    color: $color-font--white;
  }
}
