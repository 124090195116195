.search-field {
  margin: 0 calc(1rem - 6px);
  flex-grow: 1;
  position: relative;

  @include mobile {
    .icon {
      top: 0;
    }
  }

  .input__field {
    padding: 0 36px 0 36px;
  }

  @include desktop {
    flex-grow: 0;
    margin: 0 0 0 1rem;
  }
}

.search-field__input {
  margin: 0;

  @include mobile {
    height: 2.4rem;
  }

  @include desktop {
    width: 300px;
  }
}

.search-field__input--dark-mode {
  @include desktop {
    background-color: $color-background--dark;

    &:hover,
    &:focus {
      background-color: $color-background--dark;
    }
  }
}

.search-field__icon {
  position: absolute;
  top: 6px;
  left: 0;
}

.search-field__clear {
  position: absolute;
  top: 6px;
  right: 0;
}
