@import "reset";
@import "config";
@import "../../atoms/label/Label";
@import "../../atoms/icon/Icon";
@import "../../atoms/link/Link";
@import "../../atoms/button/Button";
@import "../../atoms/input/Input";
@import "../../atoms/select/Select";
@import "../../atoms/checkbox/Checkbox";
@import "../../atoms/headline/Headline";
@import "../../atoms/paragraph/Paragraph";
@import "../../atoms/messages/error-message/ErrorMessage";
@import "../../atoms/messages/success-message/SuccessMessage";
@import "../../atoms/messages/success-illustration/SuccessIllustration";
@import "../../atoms/radio/Radio";
@import "../../atoms/textarea/Textarea";
@import "../../atoms/file-upload/FileUpload";
@import "../../atoms/skeleton/Skeleton";
@import "../../atoms/tooltip/Tooltip";
@import "../../atoms/list/List";
@import "../../atoms/flag/Flag";
@import "../../atoms/hr/HorizontalRule";
@import "../../atoms/badge/Badge";
@import "../../molecules/menu/Menu";
@import "../../molecules/bookmark/Bookmark";
@import "../../molecules/category/Category";
@import "../../molecules/search-field/SearchField";
@import "../../molecules/color-picker/ColorPicker";
@import "../../molecules/tab-bar/TabBar";
@import "../../molecules/language-switcher/LanguageSwitcher";
@import "../../molecules/section/Section";
@import "../../molecules/testimonials/Testimonials";
@import "../../molecules/empty/Empty";
@import "../../molecules/feature/Feature";
@import "../../molecules/expandable/Expandable";
@import "../../molecules/error-boundary/ErrorBoundary";
@import "../../molecules/cookie-banner/CookieBanner";
@import "../../molecules/bookmarklet/Bookmarklet";
@import "../../molecules/all-features/AllFeatures";
@import "../../molecules/donate/Donate";
@import "../../organisms/categories/Categories";
@import "../../organisms/header/Header";
@import "../../organisms/footer/Footer";
@import "../../organisms/toolbar/Toolbar";
@import "../../organisms/categories-sorting/CategoriesSorting";
@import "../../organisms/sidebar/Sidebar";
@import "../../organisms/modal/Modal";
@import "../../organisms/search/Search";
@import "../../organisms/customize/Customize";
@import "../../organisms/dashboards/dashboards-list/DashboardsList";
@import "../../organisms/dashboards/dashboards-sidebar/DashboardsSidebar";
@import "../../organisms/dashboards/dashboards-tabs/DashboardsTabs";
@import "../../templates/page/Page";
@import "../../templates/extension/Extension";
@import "../../templates/fullscreen/Fullscreen";
@import "../../pages/home/Home";
@import "../../pages/add/Add";
@import "../../pages/open/Open";
@import "../../pages/resend/Resend";
@import "../../pages/forgot/Forgot";
@import "../../pages/login/Login";
@import "../../pages/account/Account";
@import "../../pages/loading/Loading";
@import "../../pages/join/Join";
@import "../../pages/legal/Legal";
@import "../../pages/contact/Contact";
@import "../../pages/next/Next";
@import "../../pages/about/About";

html {
  font-size: calc(14px + 0.2vw);

  @include desktop {
    font-size: 16px;
  }
}

/* stylelint-disable property-no-vendor-prefix */
body {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape */
  -moz-text-size-adjust: 100%; /* Prevent font scaling in landscape */
  -ms-text-size-adjust: 100%; /* Prevent font scaling in landscape */
  text-size-adjust: 100%; /* Prevent font scaling in landscape */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family--text;
  height: 100%;
  color: $color-font--dark;
}
/* stylelint-enable */

::-moz-selection {
  color: $color-font--white;
  background-color: $color-font--primary;
}

::selection {
  color: $color-font--white;
  background-color: $color-font--primary;
}

.booky--no-scrolling {
  overflow: hidden;
}

/* stylelint-disable declaration-no-important */
.booky--no-animation {
  transition: none !important;
}
/* stylelint-enable */

.booky--hide {
  display: none;
}

.booky--hide-mobile {
  @include mobile {
    display: none;
  }
}

.booky--hide-mobile-tablet {
  @include mobile-tablet {
    display: none;
  }
}

.booky--hide-tablet {
  @include tablet {
    display: none;
  }
}

.booky--hide-tablet-desktop {
  @include tablet-desktop {
    display: none;
  }
}

.booky--hide-desktop {
  @include desktop {
    display: none;
  }
}

/*
 * App loading screen
 */
.loading__logo {
  margin-right: calc(1.5rem + 6px);
  height: 28px;
  width: 84px;
}

.loading__nav {
  flex-grow: 1;
}

.loading__link {
  width: 7rem;
  margin-right: 1rem;
}

.loading__button {
  line-height: 2;
  margin-left: 1rem;
  width: 5rem;
}

.loading__spinner {
  position: absolute;
  color: $color-font--medium;
  pointer-events: none;
  left: calc(50vw - 18px);
  top: calc(50vh);
  z-index: 10;
}

.loading__spinner--hide {
  transition: opacity 0.3s linear, transform 0.4s ease-in;
  transform: translateY(-3rem);
  opacity: 0;
}

noscript {
  text-align: center;
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3%;
}

/* stylelint-disable declaration-no-important */
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}
/* stylelint-enable */
