.account__email {
  padding-bottom: 0.5rem;
  font-weight: 700;
}

.account__form {
  width: 500px;
  max-width: 100%;
}

.import__step {
  position: relative;
  padding-left: calc(1rem + 30px);
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.import__step-icon {
  position: absolute;
  left: -6px;
  top: -7px;
}

.import__drop-area {
  border: 3px dashed $color--7;
  transition: border-color 0.2s linear, background-color 0.2s linear;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
  text-align: center;

  &:focus,
  &:active {
    border-color: $color--blue;
  }

  @include desktop {
    padding: 3rem 1rem;

    &:hover {
      border-color: $color--blue;
    }
  }
}

.import__drop-area--active {
  border-color: $color--blue;
  background-color: rgba(0, 0, 0, 0.05);
}

.import__drop-area--accepted {
  padding-top: 1rem;

  @include desktop {
    padding-top: 2rem;
  }
}

.import__error {
  padding-top: 0;
  padding-bottom: 1rem;
  text-align: left;
}

.import__file {
  display: flex;
  align-items: center;
  text-align: left;
  margin-right: 6px;
  word-break: break-word;
}

.account__overview {
  display: flex;
  justify-content: space-between;
  width: 30rem;
  white-space: nowrap;

  @include mobile {
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
}

.account__overview-value {
  width: 13rem;
}

.account__amount-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  @include mobile {
    flex-wrap: wrap;
  }
}

.account__amount {
  width: 17rem;
  margin-bottom: 0.5rem;
  flex-grow: initial;
}

.account__euro {
  margin-left: 1rem;
  margin-right: 1rem;
  white-space: nowrap;
  margin-bottom: 1rem;
}

.account__update {
  margin-bottom: 1rem;
}

.account__message {
  padding: 0;
}

.account__feedback {
  margin-left: 0.5rem;
  text-transform: initial;
}
