.category {
  position: relative;
  list-style: none;
  min-width: 0;
  width: 100%;
  padding-bottom: 2rem;
  display: inline-block; // For Firefox
  page-break-inside: avoid; // For Firefox
  break-inside: avoid;
}

.category__bookmarks {
  width: 100%;
  list-style: none;
  margin-top: 0.7rem;
  page-break-inside: avoid;
  break-inside: avoid;

  &.category__bookmarks--hidden {
    height: 0;
    overflow: hidden;
  }
}

.category_bookmarks--drag {
  outline: 2px dashed $color-font--medium;
}

.category_bookmarks--drag-active {
  outline-color: $color-font--primary;
}

.category__bookmark-drag-wrapper {
  min-height: 36px;
}

.category__header {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;

  &::after {
    top: 2.5rem;
    content: "";
    height: 2px;
    left: 6px;
    position: absolute;
    right: 6px;
    pointer-events: none;
  }

  &.category__header--color1::after {
    background-color: $color--7;
  }

  &.category__header--color2::after {
    background-color: $color--8;
  }

  &.category__header--color3::after {
    background-color: $color--3;
  }

  &.category__header--color4::after {
    background-color: $color--1;
  }

  &.category__header--color5::after {
    background-color: $color--5;
  }

  &.category__header--color6::after {
    background-color: $color--4;
  }

  &.category__header--color7::after {
    background-color: $color--2;
  }

  &.category__header--color8::after {
    background-color: $color--6;
  }

  &.category__header--color9::after {
    background-color: $color--0;
  }
}

.category__header--viewOnly {
  &::after {
    top: 2.2rem;
  }
}

.category__toggle-icon {
  svg {
    transition: transform 0.2s linear, color 0.2s linear;
  }

  &.category__toggle-icon--rotate svg {
    transform: rotate(-90deg);
  }
}

/* stylelint-disable declaration-no-important */
.category__name {
  display: block;
  cursor: default;
  flex-grow: 1;
  user-select: none;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 !important;
  padding: 0 !important;

  &:active {
    color: $color-font--primary;
  }
}

.category__name--viewOnly {
  margin-left: 6px !important;
  user-select: initial;
  cursor: initial;

  &:active {
    color: $color-font--medium;
  }
}

.category__name--viewOnly-darkMode {
  &:active {
    color: $color-font--light;
  }
}
/* stylelint-enable */

.category__button {
  margin: 10px 6px 0 6px;
  display: flex;
  max-width: 100%;
}

.category__empty {
  padding: 0 6px;
  height: 2rem;
  line-height: 2;
  list-style: none;
  white-space: nowrap;
}

.category__empty--dark-mode {
  color: $color-font--white;
}

.category__skeleton {
  width: calc(100% - 12px);
  margin-bottom: 20px;
  margin-left: 6px;
}

.category__skeleton:first-child {
  margin-top: 10px;
}

.category__skeleton:last-child {
  margin-bottom: 10px;
}

.category__error {
  padding: 10px 6px 0;
}
