.add__add-button {
  width: 100%;
  margin-top: 1rem;

  &:hover,
  &:focus,
  &.button--pending {
    background-color: lighten($color-background--primary, 5%);
    color: $color-font--white;

    .icon,
    .icon__svg {
      color: $color-font--white;
    }
  }
}

.add__section {
  .label {
    margin-top: 10px;
  }

  &:first-of-type {
    margin-bottom: 1.5rem;
  }

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 1rem;

    /* stylelint-disable */
    .label:first-of-type {
      margin-top: 0;
    }
    /* stylelint-enable */
  }
}

.add__location-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .label {
    width: 100%;
  }

  .select {
    width: auto;
    flex-grow: 1;
    max-width: calc(100% - 36px);
  }

  .icon {
    margin-right: -6px;
    margin-left: 6px;
  }

  .icon--skeleton {
    margin-right: 0;
    margin-left: 12px;
  }

  &:first-of-type {
    .label {
      margin-top: 0;
    }
  }
}
