.feature__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @include mobile {
    flex-wrap: wrap;
  }

  @include desktop {
    max-width: 930px;
    margin: 0 auto;
  }
}

.feature__header {
  @include tablet {
    margin-right: 2rem;
  }

  @include desktop {
    margin-right: 3rem;
  }
}

.feature__header--right {
  @include tablet {
    margin-left: 2rem;
  }

  @include tablet-desktop {
    margin-left: 3rem;
    margin-right: 0;
    order: 1;
  }
}

.feature__illustration {
  @include mobile {
    max-width: 250px;
    max-height: 250px;
    margin-top: 1rem;
  }

  @include tablet {
    min-width: 40vw;
    max-width: 300px;
  }

  @include desktop {
    flex-shrink: 0;
  }
}
