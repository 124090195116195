.success-illustration {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 34rem;
}

.success-illustration__image {
  transition: opacity 0.4s ease-out, transform 0.4s ease-out;
  transform: translateY(-0.5rem);
  opacity: 0;
  max-width: 100%;
}

.success-illustration__image--animate {
  transform: translateY(0);
  opacity: 1;
}

.success-illustration__headline,
.success-illustration__text {
  transition: opacity 0.4s ease-out 0.3s, transform 0.4s ease-out 0.3s;
  transform: translateY(-0.5rem);
  opacity: 0;
  text-align: center;
}

.success-illustration__cta {
  transition: opacity 0.4s ease-out 0.6s, transform 0.4s ease-out 0.6s;
  transform: translateY(-0.5rem);
  opacity: 0;
}

.success-illustration__headline--animate,
.success-illustration__text--animate,
.success-illustration__cta--animate {
  transform: translateY(0);
  opacity: 1;
}
