.toolbar {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 calc(3% + 6px) 0 3%;
  position: absolute;
  top: calc(3rem + 2px);
  width: 100%;
  height: 42px;
  background-color: rgba($color-background--white, 0.95);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  z-index: 3;

  @include tablet {
    top: calc(4.4rem + 2px);
  }

  @include desktop {
    background-color: rgba($color-background--white, 0.95);
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    height: 62px;
    top: calc(5.5rem + 2px);
    padding: 0 36px;
  }
}

.toolbar--sticky {
  position: fixed;
  top: calc(3rem + 2px);

  @include tablet {
    top: calc(4.4rem + 2px);
  }

  @include desktop {
    top: calc(5.5rem + 2px);
  }
}

.toolbar--sticky-one-and-only {
  position: fixed;
  top: 0;

  @include tablet-desktop {
    top: 0;
  }
}

.toolbar--dark-mode {
  background-color: $color-background--medium;
}

.toolbar__headline {
  margin: 0;
  padding: 0;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-right: 1rem;
}

.toolbar__add-category {
  flex-shrink: 0;
}

.toolbar__share {
  flex-shrink: 0;

  @include tablet-desktop {
    margin-right: 1rem;
  }
}

.toolbar__button,
.toolbar__share--tabs {
  margin-left: calc(1rem - 6px);
}

.toolbar__share--tabs {
  margin-right: 6px;
}

.toolbar__badge {
  margin-right: 0.5rem;
  margin-left: 6px;
}

.toolbar__badge-icon {
  margin-right: 0.2rem;
}

.toolbar__add-category-icon {
  margin-right: -6px;
}
