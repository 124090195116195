.footer {
  font-size: $font-size--small;
  text-align: center;
  width: 100%;
}

.footer--sidebar {
  @include desktop {
    width: calc(100% - 36px);
    transform: translate3d(36px, 0, 0);
  }
}

.footer--shifted {
  @include desktop {
    width: calc(100% - 300px);
    transform: translate3d(300px, 0, 0);
  }
}

.footer__stats {
  background-color: $color--primary;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  padding: 1rem calc(3vw + 6px);
  color: $color-font--white;
  list-style: none;
  display: flex;
  justify-content: center;

  @include mobile {
    flex-wrap: wrap;
  }

  @include desktop {
    padding-left: 36px;
    padding-right: 36px;
  }
}

.footer__stats-item {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  line-height: 1.5;

  @include mobile {
    width: 50%;
  }
}

.footer__social {
  background-color: $color-background--medium;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.03);
  padding: calc(2rem - 4px) calc(3vw + 6px) calc(2rem + 2px) calc(3vw + 6px);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  @include desktop {
    padding-left: 36px;
    padding-right: 36px;
  }
}

.footer__social--dark-mode {
  border-top-color: rgba(0, 0, 0, 0.1);
}

.footer__social-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  user-select: none;
  text-decoration: none;

  @include mobile-tablet {
    &:active,
    &:focus {
      .footer__icon,
      .footer__label {
        color: $color-font--primary;
      }
    }
  }

  @include desktop {
    &:hover,
    &:focus {
      .footer__icon,
      .footer__label {
        color: $color-font--primary;
      }
    }
  }
}

.footer__illustration {
  margin-right: 1rem;
}

.footer__label {
  transition: color 0.2s linear;
  color: $color-font--white;
}

.footer__content {
  background-color: $color-background--dark;
  padding: 2rem calc(3vw + 6px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include desktop {
    padding-left: 36px;
    padding-right: 36px;
  }
}

.footer__menu {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  margin-top: 1.7rem;
}

.footer__menu-item {
  font-size: $font-size--small;
  padding: 0 0.5rem;
  line-height: 2;
}

.footer__languages {
  display: flex;
  justify-content: center;
}

.footer__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer__logo {
  color: $color-font--white;
  font-size: $font-size--small;
  margin: 0 1rem 2rem 1rem;
  width: 84px;

  &:focus {
    outline: 1px dashed $color-font--white;
  }
}

.footer__donate {
  margin: 0 1rem 2rem 1rem;
}

.footer__button {
  margin: 0 1rem 2rem 1rem;
}

.footer__copy {
  color: $color-font--light;
  margin-top: 2rem;
  font-size: $font-size--small;
}

.footer__copy-link {
  font-size: $font-size--small;
  color: $color-font--white;
  padding: 0 0.3rem;
}
