.headline {
  color: $color-font--dark;
  font-family: $font-family--heading;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    .headline__hash {
      opacity: 1;
    }
  }
}

.headline--centered {
  justify-content: center;
  text-align: center;
}

.headline--h1 {
  color: $color-font--medium;
  margin-top: 2rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;

  &.headline--dark-mode {
    color: $color-font--white;
  }

  @include tablet {
    font-size: 2.5rem;
    line-height: 2.7rem;
  }

  @include desktop {
    font-size: 3rem;
    line-height: 3.2rem;
  }

  &.headline--display {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3rem;

    @include tablet {
      font-size: 3.5rem;
      line-height: 4rem;
    }

    @include desktop {
      font-size: 4rem;
      line-height: 4.5rem;
    }
  }
}

.headline--h2 {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  color: $color-font--medium;

  &.headline--dark-mode {
    color: $color-font--light;
  }
}

.headline--h3 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  color: $color-font--medium;
  line-height: 1.3;

  &.headline--dark-mode {
    color: $color-font--light;
  }
}

.headline--h4 {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 0.5rem;
  padding-bottom: 0.3rem;
  color: $color-font--dark;
  line-height: 1.5;

  &.headline--dark-mode {
    color: $color-font--light;
  }
}

.headline.headline--no-margin {
  margin-top: 0;
}

.headline--color-light {
  color: $color-font--white;
}

.headline--color-medium {
  color: $color-font--medium;
}

.headline--with-link {
  display: inline-flex;
}

.headline__hash {
  color: inherit;
  margin-left: 0.5rem;
  font-size: inherit;
  opacity: 0;
  text-decoration: none;
  transition: opacity 0.2s linear, color 0.2s linear;

  &:hover,
  &:focus {
    opacity: 1;
    color: $color-font--primary;
    text-decoration: underline;
  }
}
