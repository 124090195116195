.all-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.all-features__feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem 1rem 0 1rem;
  max-width: 14rem;
  min-width: 14rem;

  @include mobile {
    max-width: calc(47% - 6px);
    min-width: calc(47% - 6px);
  }
}

.all-features__feature-name {
  line-height: 1;
}

.all-features__link {
  font-size: inherit;

  &.link--dark-mode {
    color: $color-font--light;
  }

  &.link--dark-mode:active,
  &.link--dark-mode:focus {
    color: $color-font--white;
  }

  @include desktop {
    &.link--dark-mode:hover {
      color: $color-font--white;
    }
  }
}
