.skeleton {
  max-width: 100%;
  max-height: 100%;
  width: 15rem;
  line-height: 1;
  display: inline-block;
  border-radius: 3px;
  background-color: #eee;
  background-image: linear-gradient(
    90deg,
    #eee,
    $color-background--light,
    #eee
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  animation: skeletonKeyframes 1.2s ease-in-out infinite;
}

.skeleton--darkMode {
  background-color: $color-background--dark;
  background-image: linear-gradient(
    90deg,
    $color-background--dark,
    darken($color-background--dark, 3%),
    $color-background--dark
  );
}

@keyframes skeletonKeyframes {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}
