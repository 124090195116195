.about {
  padding-top: 0;
}

.about--sticky {
  padding-top: 3rem;

  @include tablet {
    padding-top: 4rem;
  }

  @include desktop {
    padding-top: 5.6rem;
  }
}

.about__header {
  padding: 3rem 0;

  @include tablet {
    padding: 4rem 0;
  }

  @include desktop {
    padding: 5rem 0;
  }
}

.about__extension {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: center;

  @include desktop {
    margin-top: 2rem;
  }
}

.about__extension-icon {
  margin: 0 0.5rem 0.5rem;
  max-width: 75px;
  max-height: 75px;

  @include mobile {
    width: 19vw;
    height: 19vw;
  }
}

.about__extension-browser {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__members {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;

  @include desktop {
    flex-wrap: nowrap;
  }
}

.about__member {
  display: flex;
  padding: 1.5rem 1rem;
  position: relative;
  border-radius: 30px;
  margin: 0 1rem 2rem 1rem;
  width: 23rem;
  border: 2px solid $color-border--light;

  &:last-child {
    margin-bottom: 0;
  }

  @include mobile {
    margin-left: 0;
    margin-right: 0;
  }

  @include desktop {
    padding: 2rem 1rem;
    margin-bottom: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.about__members--darkMode {
  .about__member,
  .about__member-image {
    border-color: $color-background--dark;
  }
}

.about__member-image {
  margin-right: 0.5rem;
  flex-shrink: 0;
  border-radius: 30px;
  border: 2px solid $color-border--light;

  &:hover ~ .about__member-image--face {
    opacity: 1;
  }
}

.about__member-image--face {
  position: absolute;
  flex-shrink: 0;
  border-radius: 30px;
  border: 2px solid $color-border--light;
  background-color: $color-border--light;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in;
}

.about__member-header {
  font-size: $font-size--small;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.about__member-text {
  width: 100%;
  padding-bottom: 0;
}

.about__member-link {
  font-size: $font-size--small;
  display: block;
}

.about__usecase-title {
  max-width: 45rem;
}

.about__usecases {
  display: flex;
  max-width: 680px;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 1rem;
}

.about__usecase {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1rem 1rem 0 1rem;
}

.about__cluster {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;

  @include desktop {
    flex-wrap: nowrap;
  }
}
