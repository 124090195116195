@import "../../templates/booky/config.scss";

.join .section__content {
  display: flex;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  @include tablet-desktop {
    justify-content: center;
  }
}

.join__subheadline {
  margin-top: 0;
  padding-bottom: 1.5rem;
}

.join__login {
  display: block;
  margin-top: 1rem;
}

.join__website {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  pointer-events: none;
}
