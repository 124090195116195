.badge {
  border: 1px solid $color--blue;
  font-size: 12px;
  color: $color--blue;
  border-radius: 5px;
  display: inline-flex;
  text-transform: uppercase;
  padding: 0.2rem 0.2rem;
  font-weight: bold;
  line-height: 14px;
  justify-content: center;
  align-items: center;
}
