.next .section__content {
  @include desktop {
    display: flex;
    justify-content: space-between;
  }
}

.next__results {
  overflow: hidden;
  animation: 0.5s ease forwards fadeIn;
}

@keyframes fadeIn {
  from {
    transform: translateY(1rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.next__skeleton {
  height: 28px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.next__illustration {
  margin-left: 3rem;
}

.next__vote {
  position: relative;
  transition: width 1s ease-in-out;
  color: $color-font--white;
  padding: 5px 7px;
  margin-bottom: 5px;
  min-width: 3.5rem;

  &::before {
    content: "";
    position: absolute;
    background-color: $color-background--light;
    top: 0;
    left: 100%;
    height: 100%;
    width: 100vw;
  }
}

.next__option {
  align-items: baseline;
}

.next__vote--option1 {
  background-color: $color--5;
  margin-top: 0.5rem;
}

.next__vote--option2 {
  background-color: $color--6;
}

.next__vote--option3 {
  background-color: $color--3;
}

.next__text {
  margin-top: 1rem;
}
