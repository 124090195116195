// Second wrapper is needed to break out of overflow: hidden
.tooltip-wrapper {
  position: absolute;
  z-index: 10;
}

.tooltip-wrapper--bottom {
  top: calc(100% + 3px);
  left: 50%;
}

.tooltip-wrapper--right {
  left: calc(100% + 3px);
  top: 50%;
}

.tooltip-wrapper--top {
  bottom: calc(100% + 3px);
  left: 50%;
}

.tooltip-wrapper--left {
  right: calc(100% + 3px);
  top: 50%;
}

.tooltip {
  color: $color-font--dark;
  background-color: $color-background--light;
  opacity: 0;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  border-radius: 3px;
  font-size: $font-size--small;
  pointer-events: none;
  line-height: 1.3;
  border: 2px solid rgba(0, 0, 0, 0.15);
  transition: opacity 0.1s linear;
  position: fixed;
}

.tooltip--bottom,
.tooltip--top {
  transform: translateX(-50%);
}

.tooltip--right,
.tooltip--left {
  transform: translateY(-50%);
}

.tooltip--darkMode {
  color: $color-font--white;
  background-color: $color-background--dark;
}
