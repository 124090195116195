/**
 * Colors
 */
$color--primary: #eb7260;
$color--primary-lighter: #eb8174;
$color--blue: #3a9ad9;
$color--green: #92b06a;
$color--dark: #3e3e3e;
$color--black: #000;

$color-background--primary: $color--primary;
$color-background--dark: #2d2c2c;
$color-background--medium: $color--dark;
$color-background--light: #f5f5f5;
$color-background--white: #fff;
$color-background--dark-mode: #444;

$color-font--primary: $color--primary;
$color-font--dark: #14171a;
$color-font--medium: #657786;
$color-font--light: #999;
$color-font--white: #fff;

$color-border--light: #e8e8e8;

$color--0: $color--primary;
$color--1: $color--blue;
$color--2: $color--green;
$color--3: #e19d29;
$color--4: #eb65a0;
$color--5: #9b539c;
$color--6: #29aba4;
$color--7: #657786;
$color--8: $color--dark;

/**
 * Fonts
 */
/* stylelint-disable value-keyword-case */
$font-family--heading: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$font-family--text: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$font-size--small: 0.857rem;
/* stylelint-enable */

/**
 * Breakpoints
 */
$width--mobile: 600px;
$width--tablet: 1000px;
$width--hd: 1280px;
$width--fullhd: 1920px;

@mixin mobile {
  @media (max-width: #{$width--mobile}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$width--tablet}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$width--mobile + 1px}) and (max-width: #{$width--tablet}) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: #{$width--mobile + 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$width--tablet + 1px}) {
    @content;
  }
}

@mixin hd {
  @media (min-width: #{$width--hd}) {
    @content;
  }
}

@mixin fullhd {
  @media (min-width: #{$width--fullhd}) {
    @content;
  }
}
