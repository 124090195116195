.tab-bar {
  display: flex;
  flex-grow: 1;
  position: relative;
  width: calc(106% + 12px);
  margin: 0 calc(-3% - 6px);

  @include desktop {
    width: calc(100% + 72px);
    margin: 0 -36px;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 36px;
    height: 30px;
    top: 11px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s linear;
  }

  &::before {
    width: calc(3% + 6px);
  }

  @include desktop {
    &::before {
      width: 36px;
    }
  }
}

.tab-bar--start {
  &::before {
    background: linear-gradient(
      to right,
      $color-background--white,
      rgba(255, 255, 255, 0)
    );
    left: 0;
    opacity: 1;
  }
}

.tab-bar--end {
  &::after {
    background: linear-gradient(
      to left,
      $color-background--white,
      rgba(255, 255, 255, 0)
    );
    right: 0;
    opacity: 1;
  }
}

.tab-bar--darkMode {
  &.tab-bar--start {
    &::before {
      background: linear-gradient(
        to right,
        $color-background--dark-mode,
        rgba(68, 68, 68, 0)
      );
    }
  }

  &.tab-bar--end {
    &::after {
      background: linear-gradient(
        to left,
        $color-background--dark-mode,
        rgba(68, 68, 68, 0)
      );
    }
  }
}

.tab-bar__scroll-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0.5rem 3%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @include desktop {
    padding: 0.5rem 30px;
  }
}

.tab-bar__scroll-container--disabled {
  padding-bottom: 3px;
  padding-top: 0;
}

.tab-bar__tab {
  user-select: none;
  color: $color-font--medium;
  font-weight: bold;
  white-space: nowrap;
  padding: 6px;
  flex: 0 0 auto;
  cursor: pointer;
  transition: color 0.2s linear, border-bottom-color 0.2s;
  margin-right: 1rem;
  list-style: none;
  border-bottom: 2px solid transparent;
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  &:active,
  &:focus {
    color: $color-font--dark;
  }

  @include desktop {
    &:hover {
      color: $color-font--dark;
    }
  }
}

.tab-bar__tab--dark-mode {
  color: $color-font--light;

  &:active,
  &:focus {
    color: $color-font--white;
  }

  @include desktop {
    &:hover {
      color: $color-font--white;
    }
  }
}

.tab-bar__tab--active {
  color: $color--primary;
  border-bottom-color: $color--primary;

  &:focus {
    color: $color-font--dark;
    border-bottom-color: $color-font--dark;
  }

  @include desktop {
    &:hover {
      color: $color-font--dark;
      border-bottom-color: $color-font--dark;
    }
  }

  &.tab-bar__tab--dark-mode {
    &:focus {
      color: $color-font--white;
      border-bottom-color: $color-font--white;
    }

    @include desktop {
      &:hover {
        color: $color-font--white;
        border-bottom-color: $color-font--white;
      }
    }
  }
}

.tab-bar__tab--disabled {
  color: $color-font--medium;
  border-bottom-color: transparent;
  cursor: default;
  padding-bottom: 0;

  &:focus {
    color: $color-font--medium;
    border-bottom-color: transparent;
  }

  @include desktop {
    &:hover {
      color: $color-font--medium;
      border-bottom-color: transparent;
    }
  }

  &.tab-bar__tab--active {
    border-bottom-color: $color-font--medium;
  }

  &.tab-bar__tab--dark-mode {
    color: $color-font--light;
    border-bottom-color: transparent;

    &:focus {
      color: $color-font--light;
    }

    @include desktop {
      &:hover {
        color: $color-font--light;
      }
    }

    &.tab-bar__tab--active {
      border-bottom-color: $color-font--light;
    }
  }
}
