.error {
  color: $color--3;
  transition: opacity 0.4s ease, max-height 0.4s ease, padding 0.4s ease;
  max-height: 0;
  opacity: 0;
  position: relative;
  display: flex;
  padding: 0;
}

.error--animate {
  max-height: 8rem;
  opacity: 1;
  padding: 1rem 0 0;

  @include tablet-desktop() {
    max-height: 6rem;
  }
}

.error--noPadding {
  padding-top: 0;
}

.error--show {
  max-height: 6rem;
  opacity: 1;
  padding: 1rem 0;
}

.error__icon {
  margin-left: -6px;
  margin-top: -6px;
}
